import * as React from 'react'

import { navigate } from 'gatsby'

import Layout from '../../components/Layout'
import * as ROUTES from '../../constants/routes'

const ThankYouPage = ({ location }) => {
  const { state = {} } = location
  const { heading, title, subtitle, button } = state

  const onSubmit = () => {
    navigate(ROUTES.SIGN_IN)
  }

  return (
    <Layout>
      <section className="section section-gradient">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="has-text-white">{heading}</h1>
              <div className="box">
                <div className="section is-medium has-text-centered">
                  <div className="notification is-success is-light">
                    <div className="section is-medium">
                      <h1 className="title">{title}</h1>
                      <h4 className="subtitle">{subtitle}</h4>
                    </div>
                  </div>
                  <button
                    className="button is-link is-rounded is-medium"
                    type="button"
                    onClick={onSubmit}
                  >
                    {button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThankYouPage
